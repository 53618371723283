@import url('https://fonts.googleapis.com/css?family=Bitter|Open+Sans');

html, body {
  margin: 0;
  padding: 0;
}

body {
  /* background-color: #FF009E; */
  /* background-color: #FFE521; */
  /* background-color: #56F89A; */
  background-color: #222;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: #ccc;
  background-image: url(img/corchetes.png);
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
}

h1 {
  font-size: 1.6em;
}

h1, h2 {
  font-family: 'Bitter', serif;
}

h2 {
  margin: 0 0 20px;
  color: #f0f0f0;
}

a {
  color: #56F89A;
  text-decoration: none;
  border-radius: 4px;
  outline: 0;
}

a:hover {
  color: #fff;
  background-color: #FF009E;
}

section {
  text-align: center;
  padding: 10% 5%;
}

@media (min-width: 640px) {
  section {
    padding: 3%;
  }
}
