.Intro {
  background-color: #444;
  background-color: rgba(68, 68, 68, 0.97);
}

.Intro p {
  max-width: 500px;
  margin: auto;
  margin-bottom: 20px;
}
