.Resources {
  background-color: #222;
  background-color: rgba(34, 34, 34, 0.97);
}

.Resources > h2 {
  margin-bottom: 30px;
}

.Resources > a {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 5px;
  background-color: #666;
}

.SimpleIcon {
  width: 32px;
  padding: 8px;
}
