@import url(https://fonts.googleapis.com/css?family=Bitter|Open+Sans);
.Intro {
  background-color: #444;
  background-color: rgba(68, 68, 68, 0.97);
}

.Intro p {
  max-width: 500px;
  margin: auto;
  margin-bottom: 20px;
}

.Calendar {
  background-color: #333;
  background-color: rgba(51, 51, 51, 0.97);
}

.Calendar > div {
  max-width: 540px;
  margin: auto;
}

.Resources {
  background-color: #222;
  background-color: rgba(34, 34, 34, 0.97);
}

.Resources > h2 {
  margin-bottom: 30px;
}

.Resources > a {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 5px;
  background-color: #666;
}

.SimpleIcon {
  width: 32px;
  padding: 8px;
}

.Members {
  flex-wrap: wrap;
  background-color: #111;
  background-color: rgba(17, 17, 17, 0.97);
}

.Members > h2 {
  margin-bottom: 30px;
}

.Members > div {
  margin: 0 auto;
  max-width: 800px;
}

.Member {
  display: inline-block;
  line-height: 0px;
  padding: 3px;
}

.Member:hover {
  text-decoration: none;
}

.Member canvas {
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.Member canvas:hover,
.Member canvas.selected {
  filter: none;
  -webkit-filter: none;
  filter: none;
}

.App {
  display: flex;
  flex-direction: column;
}

.App header {
  display: flex;
  color: #999;
  background-color: #222;
}

.App header img {
  width: 60px;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
  background-color: #FF009E;
}

.App header h1 {
  font-family: monospace;
  margin-left: 10px;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  /* background-color: #FF009E; */
  /* background-color: #FFE521; */
  /* background-color: #56F89A; */
  background-color: #222;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  color: #ccc;
  background-image: url(/static/media/corchetes.10bb94e7.png);
  /* background-size: cover; */
  background-size: contain;
  background-position: center;
}

h1 {
  font-size: 1.6em;
}

h1, h2 {
  font-family: 'Bitter', serif;
}

h2 {
  margin: 0 0 20px;
  color: #f0f0f0;
}

a {
  color: #56F89A;
  text-decoration: none;
  border-radius: 4px;
  outline: 0;
}

a:hover {
  color: #fff;
  background-color: #FF009E;
}

section {
  text-align: center;
  padding: 10% 5%;
}

@media (min-width: 640px) {
  section {
    padding: 3%;
  }
}

